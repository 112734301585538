import '@usb-shield/react-grid/dist/library/styles/index.css'
import OneColStacked from '@/components/BlockArrayWrapper/TextBlockWithImage/DisplayViews/OneColStacked'
import { TextBlockWithImageData } from '@/components/BlockArrayWrapper/TextBlockWithImage/textBlockWithImage.model'
import TwoColBannerTitleImage from '@/components/BlockArrayWrapper/TextBlockWithImage/DisplayViews/TwoColBannerTitleImage'
import TwoColWithImage from '@/components/BlockArrayWrapper/TextBlockWithImage/DisplayViews/TwoColWithImage'

const TextBlockWithImage = ({
  title,
  shortDescription,
  ctaText,
  ctaLink,
  image,
  displayView,
  linkAsButton,
  levelDownHeads,
  fourUp,
  levelUpHeads,
}: TextBlockWithImageData) => {
  let renderDisplayView: any = ''
  let viewData: any = ''

  switch (displayView) {
    case 'OneColStacked':
      viewData = {
        title,
        shortDescription,
        ctaText,
        ctaLink,
        image,
        displayView,
        linkAsButton,
        levelDownHeads,
        fourUp,
      }

      renderDisplayView = <OneColStacked {...viewData}></OneColStacked>

      break

    case 'TwoColImageLeft':
    case 'TwoColImageRight':
    case 'TwoColSmallImageLeft':
    case 'TwoColImageLeftSmall':
      viewData = {
        title,
        shortDescription,
        ctaText,
        ctaLink,
        image,
        displayView,
        linkAsButton,
        levelDownHeads,
        fourUp,
      }

      renderDisplayView = <TwoColWithImage {...viewData}></TwoColWithImage>

      break

    case 'TwoColBannerTitleImageLeft':
    case 'TwoColBannerTitleImageRight':
      viewData = {
        title,
        shortDescription,
        image,
        displayView,
        linkAsButton,
        levelDownHeads,
        levelUpHeads,
      }

      renderDisplayView = (
        <TwoColBannerTitleImage {...viewData}></TwoColBannerTitleImage>
      )

      break
  }

  return <>{renderDisplayView}</>
}

export default TextBlockWithImage
